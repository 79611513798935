import React, { useState, useEffect, useMemo } from "react";
import './App.scss';
import { Container, Form, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CurrencyInput from 'react-currency-input-field';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faChevronRight, faChevronLeft, faSpinner, faGear } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { parse } from "@fortawesome/fontawesome-svg-core";
import { v4 as uuidv4 } from 'uuid';

function App() {

  //const api_url = useMemo(() => { return 'http://localhost:3001' }, []);
  const api_url = useMemo(() => { return 'https://api.roadmap.ai.trailblaze.marketing' }, []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [addressSelected, setAddressSelected] = useState(false);
  const [businessProfile, setBusinessProfile] = useState('');
  const [gptData, setGPTData] = useState([]);
  const [loadingGPTData, setLoadingGPTData] = useState(false);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const total_steps = steps.length;
  const progressPercentage = Math.round((currentStep / total_steps) * 100);
  const [isThinking, setIsThinking] = useState(false);
  const [fieldValidity, setFieldValidity] = useState({});
  const [formVisible, setFormVisible] = useState(true);

  useEffect(() => {
    // Check for existing UUID in localStorage
    let id = localStorage.getItem('uniqueId');
    if (!id) {
      // If not found, generate a new UUID and store it
      id = uuidv4();
      localStorage.setItem('uniqueId', id);
    }
    // Now you can use `id` for API calls or other purposes
  }, []);

  useEffect(() => {
    document.title = "Trailblaze Marketing Roadmap Generator";

    const savedData = Cookies.get('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const makeApiCall = async (endpoint, method, data) => {
    try {
      const response = await fetch(`${api_url}/${endpoint}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Unique-ID': localStorage.getItem('uniqueId'),
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const reader = response.body.getReader();
      let chunks = '';  // Assume the server sends text data

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks += new TextDecoder().decode(value);
      }

      return chunks;  // Return all data as a single string

    } catch (error) {
      console.error('Fetch error:', error);
    }
  };


  useEffect(() => {
    const fetchSteps = async () => {
      setLoading(true);
      try {
        const response = await makeApiCall('steps', 'GET');
        const json = JSON.parse(response);
        setSteps(json);

        // Check if the current step is the last step
        if (json.length > 0 && currentStep === json.length) {
          setIsFinalStep(true);
        }
      } catch (error) {
        console.error('Error fetching steps:', error);
        setError('Error fetching steps. Please try again later.');
      }
      setLoading(false);
    };

    fetchSteps();
  }, [api_url, currentStep]);

  const buildReport = async () => {
    setIsThinking(true);
    const collectedData = {};

    // Loop through all form fields in formData
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        const fieldValue = formData[fieldName];

        // Find the section (step) containing the field
        const section = steps.find((step) =>
          step.fields.some((field) => field.name === fieldName)
        );

        if (section) {
          // Create a section object if it doesn't exist
          if (!collectedData[section.id]) {
            collectedData[section.id] = {
              sectionName: section.name,
              questions: {},
            };
          }

          // Find the field within the section
          const field = section.fields.find((field) => field.name === fieldName);

          if (field) {
            // Add the field data to the questions within the section
            collectedData[section.id].questions[fieldName] = {
              question: field.label, // Use the field label as the question
              answer: fieldValue, // Store the field value as the answer
            };
          }
        }
      }
    }

    try {
      const response = await fetch(`${api_url}/build-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Unique-ID': localStorage.getItem('uniqueId')
        },
        body: JSON.stringify(collectedData),
      });

      if (response.ok) {
        console.log('Data successfully posted to /build-report');
        const responseData = await response.text(); // Handle response as text
        const responseContainer = document.getElementById('responseContainer');

        responseContainer.textContent = responseData; // Display the response text
        setFormVisible(false);
      } else {
        console.error('Error posting data to /build-report:', response.statusText);
        // Handle the error here, such as displaying an error message to the user
      }
    } catch (error) {
      console.error('Error posting data to /build-report:', error);
      // Handle the error here, such as displaying an error message to the user
    } finally {
      setIsThinking(false); // Ensure that setIsThinking(false) is called regardless of success or failure
    }
  };

  useEffect(() => {
    if (steps[currentStep - 1]) {
      const gptFields = steps[currentStep - 1].fields.filter(field => field.type === 'gpt-options');
      gptFields.forEach(field => {
        if (!gptData[field.id]) {
          gptGPTData(field);
        }
      });
    }
  }, [currentStep, steps, businessProfile]);


  const gptGPTData = async (field) => {
    if (!gptData[field.id]) {
      setIsThinking(true);
      var profileData = {
        companyName: formData.companyName,
        typeOfCompany: formData.typeOfCompany,
        companySize: formData.companySize,
        formattedRevenue: formData.annualRevenue,
        companyLocation: formData.companyLocation,
        industry: formData.industry
      }
      const questionData = { profile: businessProfile, profileData: profileData, field_id: field.id };
      try {
        setLoadingGPTData(true);  // Set loading state for GPT data
        const questionResponse = await makeApiCall(`gpt-options/`, 'POST', questionData);
        const parsedResponse = JSON.parse(questionResponse); // Parsing the JSON string
        setGPTData(prevQuestionContent => ({
          ...prevQuestionContent,
          [field.id]: [...(prevQuestionContent[field.id] || []), parsedResponse] // Storing the parsed object
        }));
        setIsThinking(false);
      } catch (error) {
        console.error("Error fetching or parsing GPT data:", error);
      } finally {
        setLoadingGPTData(false);  // Reset loading state for GPT data
      }
    }
  };


  const initial_form_data = useMemo(() => {
    if (!Array.isArray(steps)) {
      console.error('steps is not an array:', steps);
      return {};
    }
    return steps.reduce((acc, step) => {
      const stepFields = step.fields.reduce((fieldsAcc, field) => {
        fieldsAcc[field.name] = '';
        return fieldsAcc;
      }, {});
      return { ...acc, ...stepFields };
    }, {});
  }, [steps]);

  const [formData, setFormData] = useState(initial_form_data);

  useEffect(() => {
    const formattedRevenue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(formData.annualRevenue);
    const profile = `${formData.companyName}, a ${formData.typeOfCompany} with ${formData.companySize} employees who has an annual revenue of ${formattedRevenue}, located at ${formData.companyLocation} in the ${formData.industry} industry`;
    setBusinessProfile(profile);
  }, [currentStep, formData]);

  const handleInputChange = ({ target: { name, value } }) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
    setFieldValidity((prevValidity) => ({ ...prevValidity, [name]: value !== '' }));
  };

  const handleCheckboxChange = ({ target: { name, value, checked } }) => {
    setFormData(prevState => {
      const newValue = [...(prevState[name] || [])];
      if (checked) {
        newValue.push(value);
      } else {
        const index = newValue.indexOf(value);
        if (index !== -1) newValue.splice(index, 1);
      }
      return { ...prevState, [name]: newValue };
    });
    setFieldValidity((prevValidity) => ({ ...prevValidity, [name]: value !== '' }));
  };

  const handleCurrencyChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
    setFieldValidity((prevValidity) => ({ ...prevValidity, [name]: value !== '' }));
  };

  const handleAddressChange = address => {
    const field = steps[currentStep - 1].fields.find(f => f.type === 'address-autosuggest');
    if (field) {
      setFormData(prevData => ({ ...prevData, [field.name]: address }));
      setAddressSelected(false);
      setFieldValidity((prevValidity) => ({ ...prevValidity, [field.name]: addressSelected }));
    }
  };

  const handleAddressSelect = address => {
    const field = steps[currentStep - 1].fields.find(f => f.type === 'address-autosuggest');
    if (field) {
      setFormData(prevData => ({ ...prevData, [field.name]: address }));
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .catch(error => console.error('Error:', error));
      setAddressSelected(true);
      setFieldValidity((prevValidity) => ({ ...prevValidity, [field.name]: addressSelected }));
    }
  };

  useEffect(() => {
    const validationRules = {};
    steps.forEach((step) => {
      step.fields.forEach((field) => {
        if (field.required) {
          validationRules[field.name] = formData[field.name] !== '';
        }
      });
    });
    setFieldValidity(validationRules);
  }, [steps, formData]);

  const stepContinue = () => {
    if (currentStep === 1) {
      // Only save the form data if it's the first step
      Cookies.set('formData', JSON.stringify(formData), { expires: 7 });
    }
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };
  const buttonAction = isFinalStep ? buildReport : stepContinue;

  const goBack = () => {
    Cookies.set('formData', JSON.stringify(formData), { expires: 7 });
    setCurrentStep(currentStep - 1);
  };

  const resetForm = () => {
    setFormData(initial_form_data);
    Cookies.remove('formData');
    window.location.reload();
  };

  const renderField = (field, step) => {
    const isRequired = field.required;
    switch (field.type) {
      case 'text':
      case 'number':
      case 'textarea':
        return (
          <Form.Group as={Row} controlId={field.name} className="mb-3" key={field.name}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{field.label}</Form.Label>
            <Col sm={8}>
              <Form.Control
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formData[field.name] || ''}
                onChange={handleInputChange}
                as={field.type === 'textarea' ? 'textarea' : undefined}
                rows={field.rows}
                required={field.required}
              />
            </Col>
          </Form.Group>
        );
      case 'email':
        return (
          <Form.Group as={Row} controlId={field.name} className="mb-3" key={field.name}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{field.label}</Form.Label>
            <Col sm={8}>
              <Form.Control
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formData[field.name] || ''}
                onChange={handleInputChange}
                as={field.type === 'textarea' ? 'textarea' : undefined}
                rows={field.rows}
                required={field.required}
              />
            </Col>
          </Form.Group>
        );
      case 'select':
        return (
          <Form.Group as={Row} controlId={field.name} className="mb-3" key={field.name}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{field.label}</Form.Label>
            <Col sm={8}>
              <Form.Control as="select" name={field.name} value={formData[field.name] || ''} onChange={handleInputChange} required={field.required}>
                <option value="">{field.placeholder}</option>
                {field.options.map((option) => (
                  <option key={option.id} value={option.value}>{option.label}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        );
      case 'price':
        return (
          <Form.Group as={Row} controlId={field.name} className="mb-3" key={field.name}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{field.label}</Form.Label>
            <Col sm={8}>
              <CurrencyInput
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                decimalsLimit={2}
                prefix="$"
                value={formData[field.name] ? formData[field.name] : field.default}
                onValueChange={(value) => handleInputChange({ target: { name: field.name, value } })}
                className="form-control"
                required={field.required}
              />
            </Col>
          </Form.Group>
        );
      case 'address-autosuggest':
        const addressField = steps[currentStep - 1].fields.find(field => field.type === 'address-autosuggest');
        const currentFieldName = addressField ? addressField.name : null;
        return (
          <Form.Group as={Row} controlId={field.name} className="mb-3" key={field.name}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{field.label}</Form.Label>
            <Col sm={8}>
              <PlacesAutocomplete
                value={formData[currentFieldName] || ''}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
                required={field.required}
                shouldFetchSuggestions={!!((formData[currentFieldName] || '').length > 2 && !addressSelected)}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className="autocomplete-dropdown-container">
                    <Form.Control {...getInputProps({ placeholder: 'Enter company location' })} />
                    {!addressSelected && suggestions.length > 0 && (
                      <div className="autocomplete-dropdown">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => (
                          <div
                            key={suggestion.place_id}
                            {...getSuggestionItemProps(suggestion, { className: 'suggestion-item' })}
                          >
                            {suggestion.description}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          </Form.Group>
        );
      case 'url':
        return (
          <Form.Group as={Row} controlId={field.name} className="mb-3" key={field.name}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{field.label}</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="url"
                placeholder={field.placeholder}
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                required={field.required}
              />
            </Col>
          </Form.Group>
        );
      case 'gpt-options':
        const currentGPTData = gptData[field.id] || [{}]; // Default to array with an empty object

        // Extracting question and suggestions from the first object in currentGPTData
        const { question = '', suggestions = [] } = currentGPTData[0];

        return (
          <Form.Group as={Row} controlId={field.id} className="mb-3" key={field.id}>
            <Form.Label column sm={4} className={isRequired && !formData[field.name] ? 'text-danger' : ''}>{question}</Form.Label>
            <Col sm={8}>
              {suggestions.map((suggestion, index) => (
                <div key={index} className="fancy-checkbox">
                  <input
                    type="checkbox"
                    id={`${field.name}-${index}`}
                    name={field.name}
                    value={suggestion}
                    checked={formData[field.name] && formData[field.name].includes(suggestion)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`${field.name}-${index}`}>{suggestion}</label>
                </div>
              ))}
            </Col>
          </Form.Group>
        );
        break;
      default:
        return null;
    }
  };

  const renderStep = (step) => {
    return (
      <>
        <div className="d-inline-flex align-items-center">
          <h2>{step.name}</h2>
          {loadingGPTData && <FontAwesomeIcon icon={faSpinner} spin className="ms-2 fa-lg text-primary" />}
        </div>
        {!loadingGPTData && step.fields.map((field, index) => (
          <React.Fragment key={index}>
            {renderField(field, step)}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Container>
      <Form className={formVisible ? '' : 'slide-up'}>
        {error && (<div className="error">{error}</div>)}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {steps.length > 0 && renderStep(steps[currentStep - 1])}
            <Row><hr />
              <Col sm={{ span: 2 }}>
                <Button variant="danger" onClick={resetForm} disabled={isThinking}>
                  <FontAwesomeIcon icon={faRedo} className="me-1" /> Reset & Reload
                </Button>
              </Col>
              {currentStep > 1 && (
                <Col sm={{ span: 5 }}>
                  <Button variant="secondary" onClick={goBack} disabled={isThinking}>
                    <FontAwesomeIcon icon={faChevronLeft} className="me-1" /> Previous Section
                  </Button>
                </Col>
              )}
              <Col sm={{ span: currentStep > 1 ? 5 : 10 }} className="text-end">
                <Button onClick={buttonAction} disabled={isThinking}>
                  {isFinalStep ? (
                    isThinking ? (
                      <>
                        <FontAwesomeIcon icon={faGear} spin className="me-1" /> Building Report
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faGear} className="me-1" /> Build Report
                      </>
                    )
                  ) : (
                    isThinking ? (
                      <>
                        Save & Continue <FontAwesomeIcon icon={faSpinner} spin className="ms-1" />
                      </>
                    ) : (
                      <>
                        Save & Continue <FontAwesomeIcon icon={faChevronRight} className="ms-1" />
                      </>
                    )
                  )}
                </Button>
              </Col>


            </Row>
            {currentStep >= 2 && (
              <Row className="mt-3">
                <Col sm={{ span: 12 }}>
                  <ProgressBar now={progressPercentage} label={`${progressPercentage}%`} />
                </Col>
              </Row>
            )}
          </>
        )}
      </Form>
      <>
        <Row className="mt-3 d-none">
          <hr className="mt-3"></hr>
          <h2>Form Entries and Corresponding Field Names</h2>
          {Object.entries(formData).map(([key, value]) => {
            if (value) {
              return (
                <Col sm={{ span: 12 }} key={key}>
                  <strong>{key}</strong>: {value}
                </Col>
              );
            }
            return null;
          })}
        </Row>
        <Row className="mt-3" id="responseContainer">
        </Row>
      </>
    </Container>
  );
}

export default App;